
// Colors
$white: #fff;
$dark-light: #444;
$bg-light-header:rgba(118, 118, 118, 0.1);
$orange:#FD5901;
$grey:#50555B;
$light-text:#ffffff80;
$header-sticky:#010719;
$bg-b:#000;
$dark-light-text:#808080;
$bgblack-g:#3B3B3B;
$bg-dark-card:#1F1F1F;
$colorgrey:#999999;
$tabActive:#F8F8F8;
$tabActiveText:#000000;
$tabDefault:#999999;
$textDarkGrey:#5A5A5A;
$btnColor:#1F1F1F;
$seprator:#D9D9D9;
$bgLightColor:#F7F7F7;
$text-black:#000;
$text-grey:#959595;
$dark-light-color:rgb(61 61 61 / 20%);
$text-light-w:rgb(255 255 255 / 60%);
$color-light:#E3E3E3;
$text-tab:#999999;
$tabBorderColor:#282b24;
$breadcrumbInActive:#999999;
$timeLineTitle:#D9D9D9;
$timeLineCount:#282828;
$inputColor:#5B5B5B;
$textColor:#999999;
$colorDarkOpacity-20:rgba(0, 0, 0, 0.2);
$keyPointText:#5A5A5A;
$trainingCardBg:#262626;
$badgeDark:#2F2F30;
// light color
$white-opacity-40:rgba(255, 255, 255, 0.4);
$white-opacity-60:rgba(255, 255, 255, 0.6);
$darkLightColor:#1F1F1F;
$black-opacity-60:rgba(0, 0, 0, 0.6);
$border-grey:#D8D8D8;
$textLightGrey:#999999;
$white-opacity-10:rgba(255, 255, 255, 0.10);
// Fonts size
$fs-140:140px;
$fs-180:180px;
$fs-80:80px;
$fs-60:60px;
$fs-46:46px;
$fs-36:36px;
$fs-26:26px;
$fs-24:24px;
$fs-20:20px;
$fs-16:16px;
$fs-14:14px;
$fs-12:12px;

// Fonts Weight
$regular:300;
$normal:400;
$medium:500;
$semi-bold:600;
$bold:700;
$extra-bold:800;

$fontAwesome:bootstrap-icons !important;
