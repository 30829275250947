// Import SCSS Variables
@import "variables";
.footer {
  background-color: $bg-b;
  padding-top: 80px;
  position: relative;
  padding-bottom: 180px;
  .container {
    position: relative;
    z-index: 1024;
  }
  &:after {
    content: "Bhupendra Chaudhary";
    font-size: 137px;
    font-weight: 800;
    background: linear-gradient(
      to bottom,
      rgb(158 158 158 / 42%),
      rgb(255 255 255 / 0%)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    // text-fill-color: transparent;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
  }
  .footer-top {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 35px;
    .footer-logo {
      width: 20%;
      .footer-brand {
        font-weight: $extra-bold;
        line-height: 26px;
        text-transform: uppercase;
        padding: 0;
        font-size: $fs-24;
        color: $white;
        margin-right: 0;
        &:hover {
          color: $white;
        }
        &:focus {
          color: $white;
        }
      }
      @media(max-width:991.98px){
        width: 50%;
      }
    }
    .footer-social {
      margin-top: 55px;
      p {
        font-size: $fs-20;
        font-weight: $semi-bold;
        color: $white;
        margin-bottom: 10px !important;
      }
      .social-media-list {
        display: flex;
        align-items: center;
        li {
          a {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            max-width: 20px;
            img {
              max-width: 100%;
            }
          }
          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }
    .footer-wrapper-link {
      display: flex;
      width: 80%;
      flex-wrap: wrap;
      .footer-menu-list {
        width: 20%;
        padding-left: 10px;
        padding-right: 10px;
        .menu-title {
          color: $dark-light-text;
          font-size: $fs-16;
          font-weight: $medium;
          margin-bottom: 25px;
          text-transform: capitalize;
        }
        .footer-links {
          li {
            a {
              font-size: $fs-14;
              font-weight: $normal;
              color: $white;
              display: block;
              word-wrap: break-word;
              &:hover {
                opacity: 0.8;
              }
            }
            &:not(:last-child) {
              margin-bottom: 15px;
            }
          }
        }
        @media(max-width:991.98px){
          width: 33.33%;
          margin-top: 25px;
        }
      }
      @media(max-width:991.98px){
        width: 100%;
      }
    }
  }
  .bottom-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #9e9e9e4f;
    padding: 20px 0;
    .copyright-text {
      font-size: $fs-12;
      font-weight: $regular;
      color: $dark-light-text;
      margin-bottom: 0;
    }
    .brand-design {
      opacity: 1;
    }
  }
}

.go-to-top {
  font-weight: $normal;
  display: inline-flex;
  flex-direction: column;
  position: fixed;
  justify-content: center;
  bottom: 20px;
  right: 20px;
  align-items: center;
  color: $white;
  background-color: $orange;
  border: 1px solid $orange;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: $fs-12;
  z-index: 1026;
  &:hover{
    background-color: $bg-b;
    color: $orange;
  }
  i {
    display: block; 
  }
}
