// Import SCSS Variables
@import "variables";
@import "mixins";

.page-banner {
    &.training-banner-1 {
        background-image: url('../images/training/training-banner.jpg');
    }
    &.training-v2{
        background-image: url('../images/training/training-banner-2.jpg');
    }
}

.corporate-training {
    .corporate-box {
        .event-image {
            border-radius: 18px;
            overflow: hidden;

            .event-desc-img {
                max-width: 889px;

                .event-sub-title {
                    font-size: $fs-60;
                    font-weight: $bold;
                    line-height: 63px;
                    margin-bottom: 20px;
                }

                p {
                    font-size: $fs-20;
                    font-weight: $medium;
                    margin-bottom: 25px !important;
                }
            }
        }

    }

}

.experience {
    .experience-block {
        position: relative;
        overflow: hidden;
        border-radius: 6px;

        .experience-content {
            @include flex(row, start, center, wrap);
            border-radius: 6px;
            background-color: $white-opacity-10;
            backdrop-filter: blur(10px);
            position: absolute;
            left: 15px;
            right: 15px;
            bottom: 15px;
            height: 90px;
            padding: 15px;
            z-index: 111;

            .experience-title {
                font-size: $fs-24;
                font-weight: $semi-bold;
                color: $white;
                margin: 0;
                line-height: 30px;
            }
        }
    }
}


.our-training-box {
    background-color: $trainingCardBg;
    padding: 35px;
    border-radius: 20px;

    .our-training-content {
        @include flex(row, space-between, center);

        .our-training-leftside {
            max-width: 500px;
            @include flex(column, space-between, center, wrap);
            height: 100%;

            .our-training-title {
                font-size: $fs-36;
                font-weight: $semi-bold;
                color: $white;
                margin-bottom: 160px;
                position: relative;
                padding-left: 40px;

                .sr-count {
                    position: absolute;
                    left: 0;
                }
            }

            .training-desc {

                p {
                    color: $white-opacity-60;
                    font-size: $fs-20;
                    font-weight: $medium;
                    margin-bottom: 25px !important;
                }

                .list-view {
                    li {
                        @include flex(column, start, stretch, wrap);
                        color: $white;
                        font-size: $fs-16;
                        font-weight: $medium;
                        position: relative;
                        padding-left: 25px;

                        &:not(:last-child) {
                            margin-bottom: 15px;
                        }

                        &:before {
                            content: "\f26b";
                            font-family: $fontAwesome;
                            color: $orange;
                            position: absolute;
                            left: 0;
                        }
                    }
                }
            }
        }

        .our-training-picture {
            max-width: 640px;
        }
    }
}


.bh-about-training {
    .about-trainer-header {
        @include flex(row, space-between);

        .title-sec {
            max-width: 550px;
            &.w-690{
                max-width: 695px;
            }
        }

        .about-trainer-header-img {
            max-height: 210px;
            max-width: 465px;
            overflow: hidden;
        }
    }

    .about-trainer-berif {
        @include flex(row, space-between);

        .trainer-profile {
            max-height: 415px;
            max-width: 935px;
            width: 935px;
        }

        p {
            color: $textLightGrey;
            font-size: $fs-20;
            font-weight: $medium;
            max-width: 450px;
            padding: 25px;
        }
    }
}


.training-philosopher{
    .training-philosopher-grid{
        .philosopher-book-list{
            padding-top: 35px;
            padding-bottom: 35px;
            &:not(:last-child){
                border-bottom: 1px solid $border-grey;
            }
            .philosopher__no{
                background-color: $badgeDark;
            }
            .philosopher__desc{
                color: $textDarkGrey;
            }
        }
    }
    
}