// Import SCSS Variables
@import "variables";
@import "mixins";

.banner{
    &.bg-banner-dark{
        background-color: $bg-b;
        background-image: none;
    }
}

.banner-header-desc{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .banner-title{
        max-width: 398px;
    }
    .banner-text-ct{
        max-width: 433px;
        p{
            color: $colorgrey;
            font-size: $fs-16;
            font-weight: $medium;
        }
    }
}

.blogs-full-card{
    position: relative;
    overflow: hidden;
    max-height: 425px;
    border-radius: 18px;
    margin-top: 45px;
    .blogs-inner-view{
        position: absolute; 
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1012;
            padding: 40px; 
            display: flex ;
            align-items: end;
            justify-content: space-between;
        .blogs-content-text{
            max-width: 529px;
            .date-b{
                font-size: $fs-12;
                font-weight: $medium;
                text-transform: uppercase;
                color: $white;
                opacity: .6;
            }
            .title-blog{
                font-size: $fs-46;
                font-weight: $semi-bold;
                color: $white;
                margin-bottom: 0;
                margin-top: 6px;
            }
           
        }
        .blogs-logo-in{
            max-width: 160px;
        }
    }
    
    &.after-overlay{
        &::after{
            content: '';
            background-image: url('../images/blogs/gradient-blog-img.svg');
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0; 
        }
    }
}

.btn-toolbar{
    &.blog-btn{
        margin-top: 25px;
        .btn{
            &.read-more{
                border:1px solid $white;
                font-size: $fs-16;
                font-weight: $semi-bold;
                height: 50px;
                width: 150px; 
            }
        }
    }
}

.details-desc{
    max-width: 896px;
    margin-left: auto;
    margin-right: auto;
    .strategies-title{
        font-size: $fs-26;
        color: $white;
        font-weight: $semi-bold;
        margin-bottom: 10px;
    }
    .key-sub-point {
        display: block;
        opacity: 1; // Start with hidden opacity
        transform: translateY(-10px); // Slide up when hidden
        transition: all 0.3s ease-in-out; // Smooth animation for display and position
        margin-top: 15px;
        margin-bottom: 15px;

        li {
            position: relative;
            padding-left: 25px !important;
            padding-top: 0;
            padding-bottom: 0;
            font-weight: $semi-bold;
            font-size: $fs-16;
            border: none;
            cursor: auto;
            color: $white; 
            &::before {
                content: "\F26A";
                font-family: bootstrap-icons !important;
                color: $orange;
                position: absolute;
                left: 0;
                top: 0;
            }
            &:not(:last-child){
                margin-bottom: 10px;
            }
        }
    }
}