// Import SCSS Variables
@import "variables";
@import "mixins";

.banner{
    &.books-list-page{
        background-image: url('../images/books/bh-books-banner.jpg');
    }
}

.books-btn-toolbar{
    margin-top: 25px;
    .btn{
        width: 185px;
        font-size: $fs-14;
        font-weight: $semi-bold;
        &.buy-now{ 
            border-color: $white !important;
        }
        &.learn-more{
            background-color: $bg-dark-card !important;
            border-color: $white;
            color: $white!important;
            &:hover{
                background-color: $white !important;
                border-color: $white;
                color: $text-black!important;
            }
        }
    }
}