@use 'variables';

.slider-btn-white {
    width: 60px;
    height: 60px;
    background-color: $white;
    border-radius: 100px;
    position: absolute;  // Ensures that the ::after pseudo-element is positioned correctly. 
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: $bold;
    &::after { 
      font-size: $fs-20;
      color: $text-black; 
      font-weight: $bold;
    }
    &:hover{
      background-color: $dark-light-text;
      &::after {  
        color: $white;  
      }
    }
  }


// Flexbox Mixin
@mixin flex($direction: row, $justify: flex-start, $align: stretch, $wrap: nowrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  flex-wrap: $wrap;
}


@mixin placeholder-style($color: #999999, $font-size: 14px, $opacity: 1) {
  &::placeholder {
    color: $color;
    font-size: $font-size;
    opacity: $opacity;
  }
  
  // For better cross-browser support
  &:-ms-input-placeholder { // Internet Explorer 10+
    color: $color;
  }

  &::-ms-input-placeholder { // Microsoft Edge
    color: $color;
  }
}

